
import Vue from "vue";
import { Actions } from "@/store/models";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "AccountSuspended",
  components: { Logo },
  props: {},
  data() {
    return {
      imageBottom: require("/src/assets/bottom-pic-landscape.jpg"),
    };
  },
  computed: {},
  methods: {
    async logout() {
      await this.$store.dispatch(Actions.Logout);
    },
    gssiSupport() {
      window.open("https://www.geophysical.com/support", "_blank")?.focus();
    },
  },
});
